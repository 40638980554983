import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../components/css/Swiper.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import SwiperCore, {
  Pagination
} from "swiper/core";
import backgroundtelecom5g from "../images/technology/telecom-5g.mp4"
import backgroundcloud5g from "../images/technology/clouds-5g.mp4"
import backgroundrobots from "../images/technology/Robots.mp4"
import { Navigation, Keyboard } from "swiper";


SwiperCore.use([Pagination, Navigation]);



export default function SwiperSlid() {
//   const navigationPrevRef = React.useRef(null)
// const navigationNextRef = React.useRef(null)
const slideObj=[
    {
        Maintitle:"Telecom",
        Smalltitle:"Small cells + macro cells",
        videoURL:backgroundtelecom5g,
        MarketText : `Indoor and outdoor small cell for enhanced coverage
         Residential 5G broadband with fixed wireless 
         Open RAN for macro network systems`
    },
    {
        Maintitle:"Cloud",
        Smalltitle:"5G and AI",
        videoURL:backgroundcloud5g,
        MarketText : `Cloud native open RAN distributed unit (DU)
        Server form factor DU acceleration 
        Support virtualized RAN (vRAN)`
    },
    {
        Maintitle:"Enterprise",
        Smalltitle:"Private 5G",
        videoURL:backgroundrobots,
        MarketText : `Indoor small cells using private spectrum (CBRS)
        Support power over ethernet access points 
        Mission critical use cases with URLLC`   
    },
]
    return (
    <div className="container">
      <Swiper
        slidesPerView={3}
        centeredSlides={true}
        loop={true}
        spaceBetween={0}
        speed={700}    
        navigation={true}  
        modules={[Navigation, Keyboard]}
        slideToClickedSlide={true}
        // navigation={{
        //   prevEl: navigationPrevRef.current,
        //   nextEl: navigationNextRef.current,
        // }}

        // slidesPerView={window.innerWidth < 768 ? 1 : "auto"}
        pagination={{
          clickable: true
        }}
        className="mySwiper"
      >
        
           {slideObj.map((slide,index)=>{
               return(
                <SwiperSlide key={`swiper-${index}`}>
                   <div className="slides__" >
                        <div className="slideinner_text">
                            <h3><span className="orange">Markets</span> we serve</h3>
                        </div>
                        <div className="slideinner_img">
                        {/* <img src={slide.imageURL} alt={`slide-${index}`} /> */}
                        <video autoPlay muted loop crossOrigin="anonymous" playsInline  alt={`slide-${index}`}  data-autoplay>
                            <source src={slide.videoURL}/>
                        </video>
                       </div>
                       <div className="slideinner_text market_slide_text">
                            <Row>
                              <div className="market_slide_main">
                              <div className="market_slide_heading">
                              <h4>{slide.Smalltitle}</h4>
                              <h2>{slide.Maintitle}</h2>
                              </div>
                              <div className="market_slide_para">
                                <p>{slide.MarketText}</p>
                              </div>
                              </div>
                            </Row>
                        </div>
                      
                   </div>

                </SwiperSlide>

               )
           })}
                           {/* <div ref={navigationPrevRef} />
                  <div ref={navigationNextRef} /> */}
      </Swiper>
    </div>
  );
}
