import * as React from "react";
import  "./css/preloader.scss"
import Edgegif from "../images/Edge-Q-Gif.gif"

const Preloader = ()=>{

	return(	
		<div className="preloader">		
			<img src={Edgegif} alt="Edgegif" />
        </div>
	)
}
export default Preloader