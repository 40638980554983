import * as React from "react";

import  "./css/video-slider.scss"
import { Container, Row, Col, } from "react-bootstrap";
import Slider from "react-slick";

import {Link} from "gatsby"
import { Tab, Tabs,  TabPanel,TabList } from 'react-tabs'
import { BsArrowUp } from "react-icons/bs"
import {MdPlayCircle} from "react-icons/md"
import ModalVideo from 'react-modal-video'
import {EdgeQLauch,Talk5G,Fundamental5GDetails,Demo5G} from "../components/ytubeDetails"
import LeftArrow from "./leftArrow";
import RightArrow from "./rightArrow"

import { detect } from "detect-browser";







const VideoSlider = ({location})=>{
  const browser = detect();

  

    const [nav1, setNav1] = React.useState();
    const [nav2, setNav2] = React.useState();
    const [lauchList, setLauchList] = React.useState([]);
    const [talk5GList, setTalk5GList] = React.useState([]);
    const [demo5GList, setDemo5GList] = React.useState([]);

    const [fundamental5GList, setFundamental5GList] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false)
    const [photoIndex,setPhotoIndex]  = React.useState(0)
    const [mainVideoURL,setMainVideoURL] = React.useState("edgeQLauches")
   
    


 




    React.useLayoutEffect(()=>{
       setFundamental5GList([...Fundamental5GDetails])
       setLauchList([...EdgeQLauch])
      setTalk5GList([...Talk5G])
      setDemo5GList([...Demo5G])
     
      
    },[])

    const lightboxOpen = (index)=>{
      setIsOpen(true)
      setPhotoIndex(index)
      document.querySelector("html").style.overflow = "hidden"
      window.fullpage_api.setAllowScrolling(false)      
   }
  
  const closePopup = ()=>{
    setIsOpen(false)
    document.querySelector("html").style.overflow = "auto"
    window.fullpage_api.setAllowScrolling(true)
  }
   
  const medialightboxOpen = (index)=>{
    setIsOpen(true)
    setPhotoIndex(index)
    document.querySelector("html").style.overflow = "hidden"
    
 } 
 const mediaclosePopup = ()=>{
  setIsOpen(false)
  document.querySelector("html").style.overflow = "auto"
 
  
}

   
  const showMoreText = (idx)=>{ 

   const parentEl = document.querySelector(".fg-left .slick-slide.slick-active.slick-current .video-slide-content p").classList.toggle("paraHeight")
    const para = document.querySelector(".fg-left .slick-slide.slick-active.slick-current .video-slide-content p")
    const button = document.querySelector(".fg-left .slick-slide.slick-active.slick-current .video-slide-content button")  

  //  console.log(parentEl.classList)
   if(para.classList.contains("paraHeight")){
    button.textContent = "Read Less"
   }
   else{
    button.textContent = "Read More..."

   }

  
  
}

const showMoreText2 = (idx)=>{ 

  const parentEl = document.querySelector(".fgdSlider .slick-slide.slick-active.slick-current .video-slide-content p").classList.toggle("paraHeight")
   const para = document.querySelector(".fgdSlider .slick-slide.slick-active.slick-current .video-slide-content p")
   const button = document.querySelector(".fgdSlider .slick-slide.slick-active.slick-current .video-slide-content button")  

 //  console.log(parentEl.classList)
  if(para.classList.contains("paraHeight")){
   button.textContent = "Read Less"
  }
  else{
   button.textContent = "Read More..."

  }

 
 
}
  
  
   const changeVideoURL = (URL)=>{
    setMainVideoURL(URL)
   }
    
    const leftClick = ()=>{
      nav1?.slickPrev()
    }
    const rightClick = ()=>{
      nav1?.slickNext()
    }
  
    const leftSettings ={
      dots: true,
      infinite: false,
      loop:true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade:true,
      // nextArrow: <IoIosArrowDropright/>,
      // prevArrow: <IoIosArrowDropleft/>
      nextArrow: <LeftArrow leftClick={leftClick}/>,
      prevArrow: <RightArrow RightClick={rightClick} />
    

    }

    const leftSettings2 ={
      dots: true,
      infinite: false,
      loop:true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade:true,
      // nextArrow: <IoIosArrowDropright/>,
      // prevArrow: <IoIosArrowDropleft/>
      nextArrow: <LeftArrow leftClick={leftClick}/>,
      prevArrow: <RightArrow RightClick={rightClick} />
    

    }

    const rightSettings = {
      dots:false,
      loop:true,
      // loop:true,
      // fade:true,
      swipeToSlide:false,
      focusOnSelect:false,
      infinite:false,
      // arrows: true,
      // nextArrow: <AiOutlineArrowLeft />,
      // prevArrow: <AiOutlineArrowLeft />
      // onInit: ()=>{ 
      //   talk5GList.forEach((list,index)=>{
      //      console.log(list)
      //   })
      //   console.log("Work")
      // }
      

    }

    return(
       <div className="video-slider">
        <Container>
        { location.pathname !== "/" ?
        <Row>
          <Col md={12}>
          
           <div className="video-main-content">
           <h4 >Videos</h4>
          </div>
         
                  
          </Col>
        </Row>
         : null} 
        <Tabs className="slider-tabs">

       { mainVideoURL === "edgeQLauches" && typeof window !== 'undefined' && <ModalVideo channel='youtube' autoplay = "1" isOpen={isOpen} videoId={lauchList[photoIndex]?.videoURL} onClose={location.pathname !== "/" ? mediaclosePopup : closePopup} />}

       { mainVideoURL === "talk5G" && typeof window !== 'undefined' && <ModalVideo channel='youtube' autoplay = "1" isOpen={isOpen} videoId={talk5GList[photoIndex]?.videoURL} onClose={location.pathname !== "/" ? mediaclosePopup : closePopup} />}

       { mainVideoURL === "fundemental5G" && typeof window !== 'undefined' && <ModalVideo channel='youtube' autoplay = "1" isOpen={isOpen} videoId={fundamental5GList[photoIndex]?.videoURL} onClose={location.pathname !== "/" ? mediaclosePopup : closePopup} />}


       { mainVideoURL === "Demo5G" && typeof window !== 'undefined' && <ModalVideo channel='youtube' autoplay = "1" isOpen={isOpen} videoId={demo5GList[photoIndex]?.videoURL} onClose={location.pathname !== "/" ? mediaclosePopup : closePopup} />}

        {location.pathname === "/" ? 
        <div className="video__Slider__Parent">
        <Row className="align-items-center"> 
         <Col md={4}>
         <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...leftSettings} className="videoLeftSlider">
             
              {lauchList.map((tlist,index)=>{
                return(
                 <div className="video-slide-content" key={index}>
                    <h2>From The <span className="orange">Edge</span></h2>
                  <h3>{tlist.title}</h3>
                  {/* <h6>{tlist.name}</h6> */}
                   <div className="vid_para">
                     <p>{tlist.details}</p>
                   </div>

                 </div>
                  )
              })}
         </Slider>
         </Col>

         <Col md={8}>
         <Slider className="video-right-slider edgeQlaunchSlider"
          slidesToShow={6}
          {...rightSettings }
           ref={(slider2) => setNav2(slider2)}
            asNavFor={nav1}> 
            {
              lauchList.map((tlist,index)=>{
              
                return(
                  <div className="img__Slide__box" key={index}>
                 
                    <div className="slideOverlay" onClick={()=> location.pathname !== "/" ? medialightboxOpen(index) : lightboxOpen(index)}>   <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.19 127.18"><path d="M60.65,1.29H69.1c1.51.19,3,.34,4.53.57,15,2.23,27.8,8.71,38,19.94,14.22,15.7,19.47,34.21,15.7,55-2.85,15.77-10.92,28.64-23.62,38.41-14.06,10.81-30,14.92-47.59,12.64A60.51,60.51,0,0,1,27.34,116.2,62.86,62.86,0,0,1,2,74.61c-.3-1.83-.5-3.67-.74-5.51V60.65c.19-1.55.36-3.1.59-4.65a61.84,61.84,0,0,1,9.8-25.95c10.49-15.59,25-25,43.6-28C57.06,1.72,58.86,1.53,60.65,1.29ZM47.56,92.48l47.8-27.61L47.56,37.28Z" transform="translate(-1.29 -1.29)"/></svg></div>
                    <img  src={tlist.imgURL} alt={`edgeq-${index}`}/>   
                  </div>
                  )
              })
              
            }
         </Slider>
         </Col>
        </Row>  
        </div>
        :
        <div>
           {/* EdgeQLauch */}
        <TabPanel>
        <div className="video__Slider__Parent">
        <Row className="align-items-center"> 
         <Col md={4}>
         <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...leftSettings} className="videoLeftSlider">
              {lauchList.map((tlist,index)=>{
                return(
                 <div className="video-slide-content" key={index}>
                   <h2>Pioneering <span className="orange">EdgeQ </span> <a href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" className="yt_btn you_mob_vers"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Youtube</a></h2>
                  <h3>{tlist.title}</h3>
                  {/* <h6>{tlist.name}</h6> */}
                   <p>{tlist.details}</p>
                 </div>
                  )
              })}
         </Slider>
         </Col>

         <Col md={8}>
         <Slider className="video-right-slider edgeQlaunchSlider"
          slidesToShow={6}
          {...rightSettings }
           ref={(slider2) => setNav2(slider2)}
            asNavFor={nav1}> 
            {
              lauchList.map((tlist,index)=>{
              
                return(
                  <div className="img__Slide__box" key={index}>
                    <div className="slideOverlay"onClick={()=> location.pathname !== "/" ? medialightboxOpen(index) : lightboxOpen(index)}><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.19 127.18"><path d="M60.65,1.29H69.1c1.51.19,3,.34,4.53.57,15,2.23,27.8,8.71,38,19.94,14.22,15.7,19.47,34.21,15.7,55-2.85,15.77-10.92,28.64-23.62,38.41-14.06,10.81-30,14.92-47.59,12.64A60.51,60.51,0,0,1,27.34,116.2,62.86,62.86,0,0,1,2,74.61c-.3-1.83-.5-3.67-.74-5.51V60.65c.19-1.55.36-3.1.59-4.65a61.84,61.84,0,0,1,9.8-25.95c10.49-15.59,25-25,43.6-28C57.06,1.72,58.86,1.53,60.65,1.29ZM47.56,92.48l47.8-27.61L47.56,37.28Z" transform="translate(-1.29 -1.29)"/></svg></div>
                    <img src={tlist.imgURL} alt={`edgeq-${index}`}/>   
                  </div>
                  )
              })
              
            }
         </Slider>
         </Col>
        </Row>  
        </div>
        </TabPanel> 
        {/* 5G Talk */}
        <TabPanel>
        <div className="video__Slider__Parent">
        <Row className="align-items-center"> 
         <Col md={4}>
         <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...leftSettings2} className="videoLeftSlider fg-left">
              {talk5GList.map((tlist,index)=>{
          
               
                return(
                 <div className="video-slide-content" key={index}>
                   <h2>5G Industry <span className="orange">Talks</span> <a href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" className="yt_btn you_mob_vers"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Youtube</a></h2>

                  <h3>{tlist.title}</h3>
                  {/* <h6>{tlist.name}</h6> */}
                   <p >{tlist.details} </p>

                   {/* <button onClick={() => {
                     console.log('ID : ',tlist.id)
                     setShowHide(tlist.id)
                    }}>{ tlist.id == showHide ? "Read Less" : "Read More" } - { tlist.id }</button> */}

                   <button onClick={()=>showMoreText(tlist.id)}>Read More...</button>
               

                 </div>
                  )
              })}
         </Slider>
         </Col>
  
         <Col md={8}>
         <Slider className="video-right-slider talk5GSlider"
          slidesToShow={1}
          {...rightSettings }
           ref={(slider2) => setNav2(slider2)}
            asNavFor={nav1}> 
            {
              talk5GList.map((tlist,index)=>{
           

                return(
                  <div className="img__Slide__box" key={index}>
                    <div className="slideOverlay"onClick={()=> location.pathname !== "/" ? medialightboxOpen(index) : lightboxOpen(index)}><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.19 127.18"><path d="M60.65,1.29H69.1c1.51.19,3,.34,4.53.57,15,2.23,27.8,8.71,38,19.94,14.22,15.7,19.47,34.21,15.7,55-2.85,15.77-10.92,28.64-23.62,38.41-14.06,10.81-30,14.92-47.59,12.64A60.51,60.51,0,0,1,27.34,116.2,62.86,62.86,0,0,1,2,74.61c-.3-1.83-.5-3.67-.74-5.51V60.65c.19-1.55.36-3.1.59-4.65a61.84,61.84,0,0,1,9.8-25.95c10.49-15.59,25-25,43.6-28C57.06,1.72,58.86,1.53,60.65,1.29ZM47.56,92.48l47.8-27.61L47.56,37.28Z" transform="translate(-1.29 -1.29)"/></svg></div>
                    <img  src={tlist.imgURL} alt={`edgeq-${index}`}/>   
                  </div>
                  )
              })
              
            }
         </Slider>
         </Col>
        </Row>  
        </div>
        </TabPanel> 
        
         {/* Fundamental5G */}
        
        <TabPanel>
        <div className="video__Slider__Parent">
        <Row className="align-items-center"> 
         <Col md={4}>
         <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...leftSettings} className="videoLeftSlider fgdSlider">
              {fundamental5GList.map((tlist,index)=>{
                return(
                 <div className="video-slide-content" key={index}>
                   <h2>5G <span className="orange">Fundamentals</span> <a href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" className="yt_btn you_mob_vers"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Youtube</a></h2>

                  <h3>{tlist.title}</h3>

                  {/* <h6>{tlist.name}</h6> */}
                   <p>{tlist.details}</p>
                   <button onClick={()=>showMoreText2(tlist.id)}>Read More...</button>


                 </div>
                  )
              })}
         </Slider>
         </Col>

         <Col md={8}>
         <Slider className="video-right-slider fundementSlider"
          slidesToShow={6}
          {...rightSettings }
           ref={(slider2) => setNav2(slider2)}
            asNavFor={nav1}> 
            {
              fundamental5GList.map((tlist,index)=>{
              
                return(
                  <div className="img__Slide__box" key={index}>
                    <div className="slideOverlay"onClick={()=> location.pathname !== "/" ? medialightboxOpen(index) : lightboxOpen(index)}><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.19 127.18"><path d="M60.65,1.29H69.1c1.51.19,3,.34,4.53.57,15,2.23,27.8,8.71,38,19.94,14.22,15.7,19.47,34.21,15.7,55-2.85,15.77-10.92,28.64-23.62,38.41-14.06,10.81-30,14.92-47.59,12.64A60.51,60.51,0,0,1,27.34,116.2,62.86,62.86,0,0,1,2,74.61c-.3-1.83-.5-3.67-.74-5.51V60.65c.19-1.55.36-3.1.59-4.65a61.84,61.84,0,0,1,9.8-25.95c10.49-15.59,25-25,43.6-28C57.06,1.72,58.86,1.53,60.65,1.29ZM47.56,92.48l47.8-27.61L47.56,37.28Z" transform="translate(-1.29 -1.29)"/></svg></div>
                    <img  src={tlist.imgURL} alt={`edgeq-${index}`}/>   
                  </div>
                  )
              })
              
            }
         </Slider>
         </Col>
        </Row>  
        </div>
        </TabPanel>

         {/* Demo Video Addition */}
        
          <TabPanel>
        <div className="video__Slider__Parent">
        <Row className="align-items-center"> 
         <Col md={4}>
         <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...leftSettings} className="videoLeftSlider fgdSlider">
              {demo5GList.map((tlist,index)=>{
                return(
                 <div className="video-slide-content" key={index}>
                   <h2>5G  <span className="orange">Demo</span> <a href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" className="yt_btn you_mob_vers"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Youtube</a></h2>

                  <h3>{tlist.title}</h3> 

                  {/* <h6>{tlist.name}</h6> */}
                    <p>{tlist.details}</p>
                   <button onClick={()=>showMoreText2(tlist.id)}>Read More...</button>


                 </div>
                  )
              })}
         </Slider>
         </Col>

         <Col md={8}>
         <Slider className="video-right-slider fundementSlider"
          slidesToShow={6}
          {...rightSettings }
           ref={(slider2) => setNav2(slider2)}
            asNavFor={nav1}> 
            {
              demo5GList.map((tlist,index)=>{
              
                return(
                  <div className="img__Slide__box" key={index}>
                    <div className="slideOverlay"onClick={()=> location.pathname !== "/" ? medialightboxOpen(index) : lightboxOpen(index)}><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.19 127.18"><path d="M60.65,1.29H69.1c1.51.19,3,.34,4.53.57,15,2.23,27.8,8.71,38,19.94,14.22,15.7,19.47,34.21,15.7,55-2.85,15.77-10.92,28.64-23.62,38.41-14.06,10.81-30,14.92-47.59,12.64A60.51,60.51,0,0,1,27.34,116.2,62.86,62.86,0,0,1,2,74.61c-.3-1.83-.5-3.67-.74-5.51V60.65c.19-1.55.36-3.1.59-4.65a61.84,61.84,0,0,1,9.8-25.95c10.49-15.59,25-25,43.6-28C57.06,1.72,58.86,1.53,60.65,1.29ZM47.56,92.48l47.8-27.61L47.56,37.28Z" transform="translate(-1.29 -1.29)"/></svg></div>
                    <img  src={tlist.imgURL} alt={`edgeq-${index}`}/>   
                  </div>
                  )
              })
              
            }
         </Slider>
         </Col>
        </Row>  
        </div>
        </TabPanel> 

        
        </div>}
        
        <div className="tabblist"> 
         <Container className="p-0">
        
            {location.pathname !== "/" ? 
            <Row className="align-items-center">
             <Col md={9} className="p-0">  
     
             <TabList> 
               <Tab>
            <button className="tabBtn" onClick={()=>changeVideoURL("edgeQLauches")}>Pioneering EdgeQ  </button>
                                    
            </Tab> 
            <Tab>
            <button className="tabBtn" onClick={()=>changeVideoURL("talk5G")} >5G Industry Talks</button>
                                    
            </Tab>
            
            <Tab>
            <button className="tabBtn" onClick={()=>changeVideoURL("fundemental5G")}>5G Fundamentals</button>
                                    
            </Tab>

            <Tab>
            <button className="tabBtn" onClick={()=>changeVideoURL("Demo5G")}>5G Demo</button>
                                    
            </Tab> 
           
            </TabList> 
            
            </Col>
            <Col md={3} className="text-center"> 
          
            
              <a href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" className="yt_btn"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Youtube</a>
            </Col> 
            </Row>
            :
            <Row className="align-items-center video_media"> 
              <Col md={12}>
              <Link to="/media" className="yt_btn"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Media</Link>
              </Col>
              </Row>
            } 
           
            

            {/* <div>
              {[1,2,3].map((v,i) => (

                <>
                <p>{v == showHide ? Math.random() : v}</p>
                <button onClick={() => setShowHide(v)} >Read {v}</button>
                </>

              ))}
            </div> */}

        </Container>

        </div>
       
      </Tabs>
      </Container>
      </div>
    

    )
}


export default VideoSlider  