import React from 'react'

const LeftArrow = ({leftClick}) => {
  return (
    <span className ="leftArrow" onClick={leftClick}> 
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="28.5" cy="28.5" r="28" fill="white" stroke="#F68521"/>

<path d="M33 15.7513L21 27.8756L33 40" stroke="#F68521"/>

</svg>
</span>
  )
}

export default LeftArrow

