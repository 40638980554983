import React from 'react'

const rightArrow = ({RightClick}) => {
  return (
    <span className ="rightArrow" onClick={RightClick}> 
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="28.5" cy="28.5" r="28" fill="white" stroke="#F68521"/>

<path d="M23 39.9997L35 27.8753L23 15.751" stroke="#F68521"/>

</svg>
</span>
  )
}

export default rightArrow









  